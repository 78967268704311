@keyframes showb {
  0% {
    box-shadow: 0px 0px 0px #bab9c2, 0px 0px 0px #ffffff;
  }
  40% {
    box-shadow: 0px 0px 0px #bab9c2, 0px 0px 0px #ffffff;
  }
  100% {
    box-shadow: 5px 5px 10px #bab9c2, -5px -5px 10px #ffffff;
  }
}

@keyframes showbc {
  0% {
    box-shadow: 0px 0px 0px #bab9c2, 0px 0px 0px #ffffff,
      inset 2px 2px 5px #4c7ab6, inset -2px -2px 5px #8ebfff;
    background-color: #4e98f8;
    color: #dfdfdf;
  }
  40% {
    box-shadow: 0px 0px 0px #bab9c2, 0px 0px 0px #ffffff,
      inset 2px 2px 5px #4c7ab6, inset -2px -2px 5px #8ebfff;
    background-color: #4e98f8;
    color: #dfdfdf;
  }
  100% {
    box-shadow: 5px 5px 10px #bab9c2, -5px -5px 10px #ffffff,
      inset 2px 2px 5px #4c7ab6, inset -2px -2px 5px #8ebfff;
    background-color: #4e98f8;
    color: #dfdfdf;
  }
}
@keyframes showr {
  0% {
    box-shadow: 0px 0px 0px #bab9c2, 0px 0px 0px #ffffff;
  }
  40% {
    box-shadow: 0px 0px 0px #cfced4, 0px 0px 0px #f0f0f0;
  }
  100% {
    box-shadow: 3px 3px 5px #cfced4, -3px -3px 5px #f0f0f0;
  }
}

@keyframes showt {
  0% {
    box-shadow: 0px 0px 0px #afaeb7, 0px 0px 0px #ffffff;
  }
  100% {
    box-shadow: 5px 5px 9px #afaeb7, -5px -5px 9px #ffffff;
  }
}

@keyframes showd {
  0% {
    box-shadow: inset 0px 0px 0px #9f9ea6, inset 0px 0px 0px #ffffff;
  }
  50% {
    box-shadow: inset 0px 0px 0px #9f9ea6, inset 0px 0px 0px #ffffff;
  }
  100% {
    box-shadow: inset 2px 2px 4px #c6c5ce, inset -2px -2px 4px #fcfcfc;
  }
}

.Task button.Trash {
  animation: showr 2s;
  border-radius: 16px;
  background: #e0dfea;
  box-shadow: 3px 3px 5px #cfced4, -3px -3px 5px #f0f0f0;
  font-size: 0.6em;
  border: none;
  color: #aaaaaa;
  margin-left: 20px;
  position: relative;
  top: 3px;
  padding: 3px 3px 2px 3px;
}
.Task button.Trash:hover {
  color: rgb(117, 0, 0);
}

.Task button.CheckBox {
  animation: showb 2s;
  border-radius: 16px;
  background: #e0dfea;
  box-shadow: 5px 5px 10px #bab9c2, -5px -5px 10px #ffffff;
  font-size: 1em;
  font-weight: bold;
  color: #aaaaaa;
  margin-bottom: 0px;
  margin-top: 5px;
  margin-left: 5px;
  margin-right: 15px;
  padding: 5px 5px 2px 5px;
  border-style: none;
  outline: none;
  transition-duration: 0.7s;
}

.Task button.CheckBox:hover {
  background: linear-gradient(145deg, #cac9d3, #f0effa);
  box-shadow: 5px 5px 10px #cccbd5, -5px -5px 10px #f4f3ff;
  color: #026efa;
}

.Task .Checked .CheckBox {
  animation: showbc 2s;
  color: #dfdfdf;
  box-shadow: 5px 5px 10px #bab9c2, -5px -5px 10px #ffffff,
    inset 2px 2px 5px #4c7ab6, inset -2px -2px 5px #8ebfff;
  background-color: #4e98f8;
}

.Task .Checked button:hover {
  background: linear-gradient(145deg, #cac9d3, #f0effa);
  color: #026efa;
}

.Task button.CheckBox:active {
  background: #e0dfea;
  box-shadow: inset 5px 5px 9px #afaeb7, inset -5px -5px 9px #ffffff;
}

.Task .Unchecked {
  font-family: "Noto Sans JP", sans-serif;
  animation: showt 1s;
  border-radius: 12px;
  background: #e0dfea;
  box-shadow: 5px 5px 9px #afaeb7, -5px -5px 9px #ffffff;
  padding: 5px 0px 5px 0px;
  margin: 20px 15px 20px 15px;
  color: #999999;
  border: 3px solid #e0dfea;
  transition-duration: 1.2s;
}

.Task .Checked {
  font-family: "Noto Sans JP", sans-serif;
  animation: showt 1s;
  border-radius: 36px;
  background: #e0dfea;
  box-shadow: 5px 5px 9px #afaeb7, -5px -5px 9px #ffffff;
  padding: 5px;
  margin: 20px 15px 20px 15px;

  border: 3px solid #658bdd;
  color: #999999;
  transition-duration: 1.2s;
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .Task {
    margin: 30px 15% 30px 15%;
  }
  .Task input {
    font-size: 2em;
  }
  .Task button.CheckBox {
    font-size: 1.4em;
  }
}
@media screen and (min-width: 1024px) {
  .Task {
    margin: 30px 15% 30px 15%;
  }
  .Task input {
    font-size: 2em;
  }
  .Task button.CheckBox {
    font-size: 1.4em;
  }
}

.Task .Unchecked:hover {
  box-shadow: 5px 5px 9px #afaeb7, -5px -5px 9px #ffffff,
    inset 3px 3px 5px #afaeb7, inset -3px -3px 5px #ffffff;
}

.Task input {
  font-family: "Noto Sans JP", sans-serif;
  animation: showd 2s;
  border-radius: 11px;
  background: #e0dfea;
  box-shadow: inset 2px 2px 4px #c6c5ce, inset -2px -2px 4px #fcfcfc;
  font-size: 1.1em;
  color: #026efa;
  text-shadow: 0px 0px 5px #6595d8;
  margin: 0px;
  margin-top: 5px;
  margin-left: 0px;
  padding: 6px 3px 2px 12px;
  border-style: none;
  outline: none;

  transition-duration: 0.5s;

  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 300px;
  width: 77%;
}
.Task .Checked input,
.Task .Checked select {
  color: #999999;
  text-shadow: none;
}

input::placeholder {
  color: #a3a2aa;
}

.Task input[type="number"] {
  max-width: 70px;
  font-weight: normal;
  color: #6595d8;
  text-shadow: none;
  margin-left: 10px;
  font-size: 1.2em;
  padding: 2px 3px 2px 12px;
}

.Task input[type="date"] {
  max-width: 100px;
  font-weight: normal;
  color: #6595d8;
  text-shadow: none;
  margin-left: 10px;
  font-size: 1.2em;
  padding: 2px 3px 2px 12px;
}

.Task .InputNum {
  display: inline-block;
}

.Task input:focus {
  color: #026efa;
  text-shadow: 0px 0px 5px #6595d8;
}
.Task input:hover {
  color: #026efa;
  text-shadow: 0px 0px 5px #6595d8;
}

.Task select {
  animation: showd 2s;
  border-radius: 11px;
  background: #e0dfea;
  box-shadow: inset 2px 2px 4px #c6c5ce, inset -2px -2px 4px #fcfcfc;
  font-size: 1.1em;
  margin-right: 10px;
  margin-left: 30px;
  color: #6595d8;
  text-shadow: none;
  padding: 7px 3px 3px 5px;
  border-style: none;
  outline: none;
  transition-duration: 0.5s;
}
